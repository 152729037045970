import { Card, Typography, Button, Divider, Space, Image, Row, Col, Grid, Modal } from 'antd';
import { useState } from 'react';

const { Text, Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

import './style.scss';

import {
  bankImageUrl,
  financingSolution,
  getScreenSize,
  licensingSections,
  processFlowSteps,
} from './constant';
import { useGetCreditBook } from './creditBook';

const FinancingPage: React.FC = () => {
  const breakpoints = useBreakpoint();
  const { lg, md, sm, xl, xs, xxl } = breakpoints;
  const [isTandCModal, setIsTandCModal] = useState(false);

  const screenSize = getScreenSize(breakpoints);
  const { refetch: creditBookUrl, isFetching } = useGetCreditBook(setIsTandCModal);

  return (
    <>
      <div className="financing-main">
        {/* Header Section */}
        <header className="financing-header">
          <Space
            direction={xl || xxl ? 'horizontal' : 'vertical'}
            className="financing-header-space"
          >
            <Space
              direction="vertical"
              className={`header-space-title header-space-title-${screenSize}`}
            >
              <div>
                <Text className={`header-space-text header-space-text-${screenSize}`}>
                  Unlock the potential of your business with <strong>fast</strong>,{' '}
                  <strong>flexible</strong>, and <b>affordable</b> financing.
                </Text>
                <br />
                <Button
                  className="financing-button"
                  type="primary"
                  onClick={() => setIsTandCModal(true)}
                  loading={isFetching}
                >
                  Apply For Financing
                </Button>
              </div>
            </Space>
            <Row justify="center">
              <Image
                src={bankImageUrl}
                alt="Bank image"
                preview={false}
                className={`financing-bank-image financing-bank-image-${screenSize}`}
              />
            </Row>
          </Space>
        </header>
        {/* Features Section */}

        <section className="financing-solution">
          <Row justify="center">
            <Text strong className="financing-solution-header">
              Empower your business with seamless financing solutions
            </Text>
          </Row>
          <Row className={`financing-solution-row financing-solution-row-${screenSize}`}>
            {financingSolution.map(({ description, imageUrl, title, key }) => (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={6}
                xxl={6}
                key={key}
                className={`financing-solution-col financing-solution-col-${screenSize} financing-solution-col-${screenSize}-${key} financing-solution-col-${key}`}
              >
                <Space direction="vertical">
                  <Image src={imageUrl} alt={title} width={40} height={40} preview={false} />
                  <Space direction="vertical" size={'small'}>
                    <Text strong className="financing-solution-title">
                      {title}
                    </Text>
                    <Text>{description}</Text>
                  </Space>
                </Space>
              </Col>
            ))}
          </Row>
        </section>
        {/* Processing Flow section */}
        <Row className="financing-process-flow" justify="center" align="middle">
          <div className="flow-container">
            <div className="center-card">
              <Title level={xl || xxl ? 2 : lg ? 3 : md ? 4 : 5}>QUICK AND EASY PROCESS</Title>
            </div>
            {processFlowSteps.map(({ title, imageUrl, step }) => (
              <Row key={step} className={`step step-${step}`} justify="center" align="middle">
                <Col md={10} lg={10} xl={10} xxl={10} style={{ paddingTop: 8 }}>
                  <Image
                    src={imageUrl}
                    alt={title}
                    className={`financing-process-flow-image financing-process-flow-image-${screenSize}`}
                    preview={false}
                  />
                </Col>
                <Col md={14} lg={14} xl={14} xxl={14}>
                  <Text type="secondary">Step {step}</Text>
                  <br />
                  <Text strong>{title}</Text>
                </Col>
              </Row>
            ))}
          </div>
        </Row>
        {/* Licensing Section */}
        <Row justify="center" gutter={[24, 24]} className="financing-license">
          <Space
            direction={xl || xxl ? 'horizontal' : 'vertical'}
            size={'large'}
            align={xs || sm ? 'center' : undefined}
            className="financing-license-space"
          >
            {licensingSections.map(({ key, title, imageUrl, description, linkText, linkUrl }) => (
              <Card key={key} className="financing-license-card" bordered={false}>
                <Row justify="center" align="middle" gutter={[16, 16]}>
                  <Image
                    preview={false}
                    width={'42.46%'}
                    height={'42.46%'}
                    src={imageUrl}
                    alt={title}
                  />
                  <Divider className="financing-license-divider" />
                  <Title level={3}>{title}</Title>
                  <Text className="financing-license-text">{description}</Text>
                  <a href={linkUrl} className="financing-license-link">
                    {linkText}
                  </a>
                </Row>
              </Card>
            ))}
          </Space>
        </Row>

        {/* "Grow Your Business Today" Section */}
        <footer className="financing-footer">
          <Row align="middle" justify="center">
            <Space direction="vertical" size={32} align="center">
              <Title level={xxl ? 2 : xl || lg ? 3 : md ? 4 : 5}>Grow your business today</Title>
              <Button
                type="primary"
                onClick={() => setIsTandCModal(true)}
                loading={isFetching}
                className="financing-button"
              >
                Apply For Financing
              </Button>
              <div className="financing-footer-border">
                <Text className="financing-footer-border-info">
                  For further information please contact: +92 318 0110801
                </Text>
              </div>
            </Space>
          </Row>
        </footer>
      </div>
      <ShowTandCModal
        isTandCModal={isTandCModal}
        setIsTandCModal={setIsTandCModal}
        creditBookUrl={creditBookUrl}
      />
    </>
  );
};
const ShowTandCModal = ({
  isTandCModal,
  setIsTandCModal,
  creditBookUrl,
}: {
  isTandCModal: boolean;
  setIsTandCModal: React.Dispatch<React.SetStateAction<boolean>>;
  creditBookUrl: any;
}) => {
  return (
    <Modal
      title="Terms and Conditions"
      className="term_condition"
      visible={isTandCModal}
      onCancel={() => setIsTandCModal(false)}
      onOk={() => creditBookUrl()}
      okText={'I Accept'}
      cancelText={'Close'}
      centered
      bodyStyle={{ maxHeight: 500, overflow: 'auto' }}
      destroyOnClose
    >
      <Typography>
        <Title level={4}>1. Introduction</Title>
        <Paragraph>
          These Terms and Conditions (“Terms”) govern the relationship between CreditBook Financial
          Services Pvt. Limited (“we,” “us,” “our”) and the end customers (“Customer,” “you,”
          “your”) of our partner companies (“Partner Company”) who choose to utilize our financing
          services. By applying for and/or accepting financing through us, you agree to be bound by
          these Terms.
        </Paragraph>

        <Divider />

        <Title level={4}>2. Definitions</Title>
        <Paragraph>
          <Text strong>2.1. Financing/Lending Company:</Text> The entity providing capital/financing
          services.
        </Paragraph>
        <Paragraph>
          <Text strong>2.2. Partner Company:</Text> The business entity collaborating with the
          Financing Company to offer products or services to Customers.
        </Paragraph>
        <Paragraph>
          <Text strong>2.3. Customer:</Text> The individual or entity purchasing goods or services
          from the Partner Company and entering into a financing agreement with the Financing
          Company.
        </Paragraph>

        <Divider />

        <Title level={4}>3. Eligibility</Title>
        <Paragraph>
          <Text strong>3.1.</Text> You must be at least 18 years old (or the age of majority in your
          jurisdiction) and legally capable of entering into a binding agreement.
        </Paragraph>
        <Paragraph>
          <Text strong>3.2.</Text> Approval for financing is subject to a credit check and other
          assessments conducted at our discretion.
        </Paragraph>

        <Divider />

        <Title level={4}>4. Financing Agreement</Title>
        <Paragraph>
          <Text strong>4.1.</Text> The terms of your financing agreement, including the loan amount,
          interest rate, repayment schedule, and any applicable fees, will be outlined in your
          Financing Agreement.
        </Paragraph>
        <Paragraph>
          <Text strong>4.2.</Text> You must review and accept the Financing Agreement before funds
          are disbursed or applied to your purchase with the Partner Company.
        </Paragraph>

        <Divider />

        <Title level={4}>5. Repayment Obligations</Title>
        <Paragraph>
          <Text strong>5.1.</Text> You agree to repay the loan according to the terms outlined in
          the Financing Agreement.
        </Paragraph>
        <Paragraph>
          <Text strong>5.2.</Text> Payments must be made in the specified amounts and on the
          scheduled due dates.
        </Paragraph>
        <Paragraph>
          <Text strong>5.3.</Text> Late or missed payments may result in additional fees, penalties,
          or adverse credit reporting.
        </Paragraph>

        <Divider />

        <Title level={4}>6. Interest and Fees</Title>
        <Paragraph>
          <Text strong>6.1.</Text> Interest rates and fees are specified in the Financing Agreement.
        </Paragraph>
        <Paragraph>
          <Text strong>6.2.</Text> Additional fees may apply for late payments, early repayments (if
          applicable), or other breaches of the Financing Agreement.
        </Paragraph>

        <Divider />

        <Title level={4}>7. Customer Responsibilities</Title>
        <Paragraph>
          <Text strong>7.1.</Text> You must ensure the accuracy of all information provided during
          the application process.
        </Paragraph>
        <Paragraph>
          <Text strong>7.2.</Text> It is your responsibility to inform us promptly of any changes to
          your contact details or financial circumstances that may affect your ability to meet
          repayment obligations.
        </Paragraph>

        <Divider />

        <Title level={4}>8. Rights and Obligations of the Financing/Lending Company</Title>
        <Paragraph>
          <Text strong>8.1.</Text> We reserve the right to approve, decline, or modify your
          financing application.
        </Paragraph>
        <Paragraph>
          <Text strong>8.2.</Text> We may assign or transfer your Financing Agreement to a third
          party, provided this does not affect your rights or obligations under the agreement.
        </Paragraph>
        <Paragraph>
          <Text strong>8.3.</Text> We may report your payment history to credit bureaus or agencies.
        </Paragraph>

        <Divider />

        <Title level={4}>9. Termination and Default</Title>
        <Paragraph>
          <Text strong>9.1.</Text> The Financing Agreement may be terminated by mutual consent or
          upon full repayment of the loan.
        </Paragraph>
        <Paragraph>
          <Text strong>9.2.</Text> Default occurs if you fail to make payments as agreed or breach
          any term of the Financing Agreement. In the event of default, we may:
        </Paragraph>
        <Paragraph>
          a. Accelerate the remaining balance for immediate payment.
          <br />
          b. Take legal action to recover the debt.
          <br />
          c. Report the default to credit agencies.
        </Paragraph>

        <Divider />

        <Title level={4}>10. Dispute Resolution</Title>
        <Paragraph>
          <Text strong>10.1.</Text> Any disputes arising from these Terms or the Financing Agreement
          will first be addressed through good-faith negotiations.
        </Paragraph>
        <Paragraph>
          <Text strong>10.2.</Text> If the dispute cannot be resolved, it will be submitted to
          arbitration or court proceedings as specified in the Financing Agreement.
        </Paragraph>

        <Divider />

        <Title level={4}>11. Limitation of Liability</Title>
        <Paragraph>
          <Text strong>11.1.</Text> We are not responsible for the quality, delivery, or performance
          of goods or services provided by the Partner Company.
        </Paragraph>
        <Paragraph>
          <Text strong>11.2.</Text> Our liability is limited to the value of the financing provided
          under your Financing Agreement.
        </Paragraph>

        <Divider />

        <Title level={4}>12. Amendments</Title>
        <Paragraph>
          <Text strong>12.1.</Text> We reserve the right to amend these Terms at any time. Notice of
          any changes will be provided in writing or electronically.
        </Paragraph>
        <Paragraph>
          <Text strong>12.2.</Text> Continued use of our financing services after notification of
          changes constitutes acceptance of the amended Terms.
        </Paragraph>

        <Divider />

        <Title level={4}>13. Governing Law</Title>
        <Paragraph>
          These Terms and the Financing Agreement are governed by the laws of Pakistan.
        </Paragraph>

        <Divider />

        <Title level={4}>14. Contact Information</Title>
        <Paragraph>
          For questions or concerns regarding these Terms, please contact us at:
        </Paragraph>
        <Paragraph>
          <Text strong>CreditBook Financial Services (Pvt) Limited</Text>
          <br />
          GA-51-A6, Korangi Creek Industrial Park
          <br />
          Korangi Creek, Karachi, Karachi City, Sindh 75190
          <br />
          <Text strong>Phone:</Text> +923180110801
          <br />
          <Text strong>Email:</Text> cbfs@creditbook.pk
        </Paragraph>
      </Typography>
    </Modal>
  );
};
export default FinancingPage;
