import React from 'react';
import { Link } from 'react-router-dom';

import CustomerDataPlatform from '@/components/icons/customerDataPlatform';
import DollarIcon from '@/components/icons/dollarIcon';
import LogisticIcon from '@/components/icons/logistic';
import OmniChannelIcon from '@/components/icons/oe';
import OmniRetainIcon from '@/components/icons/or';
import Pim from '@/components/icons/pim';
import ShopdeskIcon from '@/components/icons/shopdesk';
import XpayIcon from '@/components/icons/xpay';
import { options } from '@/constants';
import storage from '@/utils/storage';

type opt = {
  hasAccess?: boolean;
  tabKey: any;
  heading: string;
};

const Products = ({ tabKey, heading }: opt) => {
  const apps = storage.getApps();
  const appsLength = apps && apps.length > 0;

  const paymentOptions = options.filter((solution) => solution?.module == 'payment');
  const commerceOptions = options.filter((solution) => solution?.module == 'commerce');
  const marketingOptions = options.filter((solution) => solution.module == 'marketing');

  const solutions = [];
  if (tabKey?.tabKey === '4') {
    solutions.push(...paymentOptions);
  } else if (tabKey?.tabKey === '2') {
    solutions.push(...commerceOptions);
  } else if (tabKey?.tabKey === '3') {
    solutions.push(...marketingOptions);
  } else {
    solutions.push(...options);
  }

  const filteredSolutions = solutions.filter((solution) => {
    if (solution.id === 10) {
      return apps?.includes('xstak-copilot-admin');
    }
    return true;
  });
  filteredSolutions.sort((a, b) => {
    const tilesWithAccess = apps?.includes(a.permission);
    const tilesWithoutAccess = apps?.includes(b.permission);
    if (tilesWithAccess && !tilesWithoutAccess) {
      return -1;
    } else if (!tilesWithAccess && tilesWithoutAccess) {
      return 1;
    }
    return 0;
  });

  return (
    <div
      className={`dashboard__tilesContainer col-1 ${
        appsLength ? 'tilesContainer__two' : 'tilesContainer__one'
      } `}
    >
      {filteredSolutions.map(({ id, permission, imgSrc, type, link, text, xstakURL }) => {
        let isExist, isShopifyOnboard;
        if (id === 1) {
          isExist = false;
        } else {
          isExist = appsLength ? apps.includes(permission) : false;
        }
        if (id === 4 || id === 6) {
          isShopifyOnboard = appsLength ? apps.includes('shopify-xap-oms') : false;
        }
        return (
          !isShopifyOnboard && (
            <SingleCard
              key={id}
              isExist={isExist}
              link={link}
              id={id}
              text={text}
              type={type}
              xstakURL={xstakURL}
              imgSrc={imgSrc}
              heading={heading}
            />
          )
        );
      })}
    </div>
  );
};

const SingleCard = ({ isExist, link, id, text, type, xstakURL, imgSrc, heading }: any) => {
  const obj: any = {
    heading: heading,
    text: text,
  };
  const getIcons = (type: string) => {
    const icon: any = {
      oe: <OmniChannelIcon />,
      or: <OmniRetainIcon />,
      shopdesk: <ShopdeskIcon />,
      xpay: <XpayIcon />,
      cdp: <CustomerDataPlatform />,
      lm: <LogisticIcon />,
      pim: <Pim />,
      financing: <DollarIcon />,
    };
    return icon?.[type];
  };

  return (
    <React.Fragment key={id}>
      {isExist ? (
        <Link
          to={link}
          target="_self"
          onClick={(e) => {
            if (e.ctrlKey) {
              return;
            } else {
              window.location.assign(link);
              window.sessionStorage.setItem('module', JSON.stringify(obj));
            }
          }}
          className="product-dashboard__tiles"
        >
          <div className="arrow-icons">
            {getIcons(type)}
            <img src={'/logos/right-arrow.svg'} className="product_tile_image arrow_image" />
          </div>
          <h1 className="h6 text-lg">{text}</h1>
          <div className="explore_channel">
            <img src={imgSrc} className="product_tile_image" />
          </div>
        </Link>
      ) : (
        <a
          href={xstakURL}
          target="_blank"
          rel="noopener noreferrer"
          className="dashboard__tiles"
          onClick={() => window.sessionStorage.removeItem('module')}
        >
          <div className="arrow-icons">{getIcons(type)}</div>
          <h1 className="h6 text-lg">{text}</h1>
          <div className="explore_channel">
            <img src={imgSrc} className="product_tile_image" />
            <div className="demo_badge_div">
              <p className="demo_badge">GET A DEMO</p>
            </div>
          </div>
        </a>
      )}
    </React.Fragment>
  );
};
export default Products;
