export const API_URL =
  (process.env.REACT_APP_API_URL as string) || 'https://api-copilot-stage-local.xstak.com';
export const JWT_SECRET = '123456' as string;
export const OMNIRETAIN_URL = process.env.REACT_APP_OMNIRETAIN_URL as string;
export const HYPR_URL = process.env.REACT_APP_HYPR_URL as string;
export const OMNICHANNEL_ENGINE_URL =
  (process.env.REACT_APP_OMNICHANNEL_ENGINE_URL as string) ||
  'https://oe-unc-stage-local.xstak.com';
export const SHOPDESK_URL = process.env.REACT_APP_SHOPDESK_URL as string;
export const CB_WEB_URL = process.env.CB_WEB_URL as string;
export const CB_ADAPTER_BASE_URL = process.env.CB_ADAPTER_BASE_URL as string;
