import * as React from 'react';

const NewTextIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="38" height="20" fill="none" viewBox="0 0 38 20">
    <rect width="38" height="20" fill="url(#paint0_linear_20083_83279)" rx="5"></rect>
    <path
      fill="#000"
      d="M13.999 5.273V14H12.83L8.318 7.489h-.08V14H6.983V5.273h1.168l4.526 6.524h.08V5.273zm4.65 8.86q-.958 0-1.653-.414a2.8 2.8 0 0 1-1.07-1.172q-.374-.759-.374-1.773 0-1.005.375-1.773.376-.77 1.048-1.201.678-.43 1.581-.43.546 0 1.057.178.515.18.929.571.413.388.652 1.015.243.626.243 1.52v.444h-5.165v-.933h3.972a2 2 0 0 0-.213-.912 1.54 1.54 0 0 0-.584-.635 1.63 1.63 0 0 0-.882-.234q-.554 0-.963.272-.406.27-.627.703-.221.435-.221.938V11q0 .678.238 1.15.239.474.665.72.43.249 1.001.248.375 0 .678-.107.303-.105.524-.32.221-.212.341-.528l1.146.23q-.144.524-.515.917a2.5 2.5 0 0 1-.925.609q-.554.213-1.257.213M24.303 14l-1.94-6.545h1.25l1.316 4.858h.064l1.317-4.858h1.252l1.304 4.836h.069l1.308-4.836h1.253L29.55 14h-1.218l-1.355-4.803h-.098L25.525 14z"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_20083_83279"
        x1="0"
        x2="38"
        y1="10"
        y2="10"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00C3FF"></stop>
        <stop offset="1" stopColor="#FFFF1C"></stop>
      </linearGradient>
    </defs>
  </svg>
);

export default React.memo(NewTextIcon);
