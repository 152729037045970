import * as React from 'react';

const DollarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
    <rect width="40" height="40" fill="#262626" rx="6"></rect>
    <path
      fill="#fff"
      d="M21.875 17.983a1 1 0 1 0 1.89-.654zm-.675-1.55.044-.998-.044-.001zM18.8 20.1v1zm2.4 3.667v1zm-2.4 0-.044 1h.044zm-.675-1.55a1 1 0 0 0-1.89.655zM21 14.6a1 1 0 1 0-2 0zm-2 1.834a1 1 0 1 0 2 0zm2 7.333a1 1 0 1 0-2 0zM19 25.6a1 1 0 1 0 2 0zm4.765-8.271a2.83 2.83 0 0 0-.969-1.335l-1.204 1.596a.83.83 0 0 1 .283.393zm-.969-1.335a2.8 2.8 0 0 0-1.552-.56l-.088 1.999a.8.8 0 0 1 .436.157zm-1.596-.56h-2.4v2h2.4zm-2.4 0c-.749 0-1.463.303-1.986.836l1.427 1.401a.78.78 0 0 1 .559-.237zm-1.986.836A2.85 2.85 0 0 0 16 18.267h2a.85.85 0 0 1 .24-.596zM16 18.267c0 .746.29 1.464.814 1.997l1.427-1.401a.85.85 0 0 1-.241-.596zm.814 1.997a2.78 2.78 0 0 0 1.986.836v-2a.78.78 0 0 1-.56-.237zm1.986.836h2.4v-2h-2.4zm2.4 0c.206 0 .408.084.56.238l1.426-1.401A2.78 2.78 0 0 0 21.2 19.1zm.56.238a.85.85 0 0 1 .24.596h2a2.85 2.85 0 0 0-.814-1.997zm.24.596a.85.85 0 0 1-.24.595l1.426 1.402A2.85 2.85 0 0 0 24 21.934zm-.24.595a.78.78 0 0 1-.56.238v2c.749 0 1.463-.303 1.986-.836zm-.56.238h-2.4v2h2.4zm-2.356.001a.8.8 0 0 1-.436-.158l-1.204 1.597c.45.339.99.534 1.552.56zm-.436-.158a.83.83 0 0 1-.283-.392l-1.89.654c.184.53.52.996.969 1.335zM19 14.6v1.834h2V14.6zm0 9.167V25.6h2v-1.833zM28.6 20a8.6 8.6 0 0 1-8.6 8.6v2c5.854 0 10.6-4.745 10.6-10.6zM20 28.6a8.6 8.6 0 0 1-8.6-8.6h-2c0 5.855 4.746 10.6 10.6 10.6zM11.4 20a8.6 8.6 0 0 1 8.6-8.6v-2C14.146 9.4 9.4 14.146 9.4 20zm8.6-8.6a8.6 8.6 0 0 1 8.6 8.6h2c0-5.854-4.746-10.6-10.6-10.6z"
    ></path>
  </svg>
);

export default React.memo(DollarIcon);
