/* eslint-disable no-restricted-imports */

import { DashboardOutlined, ClusterOutlined } from '@ant-design/icons';
import { NavBar } from '@xstak/ui-library-dev-v2';
import { Layout as AntdLayout, LayoutProps, Row } from 'antd';
import { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

import { Head } from '@/components/Head';
import NewTextIcon from '@/components/icons/newTextIcon';
import Dashboard from '@/features/dashboard/routes/Dashboard';
// import { Header } from '@/features/header/Header';
// import { MobileHeader } from '@/features/header/MobileHeader';
import Financing from '@/features/financing';
import { UserManagement } from '@/features/roles';
// import { Urls } from '@/lib/axios';
// import useWindowSize from '@/lib/custom-hooks/useWindowSize';
// import { getRefreshToken } from '@/lib/getRefreshToken';
import storage from '@/utils/storage';

const { Content: LayoutContent } = AntdLayout;
const App = () => {
  // const [size] = useWindowSize();
  // useEffect(() => {
  //   const token = storage.getToken();
  //   if (token) {
  //     getRefreshToken(true, Urls.POST.refreshToken);
  //   }
  // }, []);
  const Routes: any = [
    {
      title: 'Dashboard',
      id: 1,
      icon: <DashboardOutlined />,
      url: '/app',
      wholeLink: <Link to="/app">Dashboard</Link>,
    },
  ];
  const apps = storage.getApps();
  const location = useLocation();
  const { pathname } = location;
  const selectedKeys: string[] = pathname.split('/');

  const [openKeys, setOpenKeys] = useState<string[]>([selectedKeys[1]]);

  const handleOpenSubMenu = (keys: string[]) => {
    const latestOpenSubMenu = keys[keys.length - 1];
    setOpenKeys([latestOpenSubMenu]);
  };

  if (apps !== null) {
    navigation?.forEach((elem, id) => {
      const hasPermission = apps?.includes('xstak-copilot-admin');
      if (hasPermission && (elem.id === 2 || elem.id === 3)) {
        Routes.push({
          title: elem.title,
          id,
          icon: elem.icon,
          url: elem.route,
          wholeLink: <Link to={elem.route}>{elem.title}</Link>,
        });
      }
    });
  }
  return (
    <>
      {/* {size > 768 ? <Header /> : <MobileHeader />} */}
      <Head title="XAP - XStak Admin Panel" />
      <NavBar
        menu={Routes}
        app={'xstak'}
        location={location}
        selectedKeys={selectedKeys}
        handleOpenSubMenu={handleOpenSubMenu}
        openKeys={openKeys}
      >
        <Layout className="site-layout-header">
          <Layout className="site-layout">
            <LayoutContent className="site-layout-content">
              <Outlet />
            </LayoutContent>
          </Layout>
        </Layout>
      </NavBar>
    </>
  );
};

const ProtectedRoutes = () => {
  const pathElements = [
    {
      path: '/app',
      element: <Dashboard />,
    },
  ];
  const apps = storage.getApps();

  if (apps?.includes('xstak-copilot-admin')) {
    pathElements.push({
      path: '/roles',
      element: <UserManagement />,
    });
    pathElements.push({
      path: '/financing',
      element: <Financing />,
    });
  }

  return [
    {
      path: '/',
      element: <App />,
      children: pathElements,
    },
  ];
};
export default ProtectedRoutes;

const navigation = [
  {
    id: 2,
    route: '/roles',
    title: 'User Management',
    icon: <ClusterOutlined />,
  },
  {
    id: 3,
    route: '/financing',
    title: (
      <Row justify="space-between" align="middle">
        Financing
        <NewTextIcon />
      </Row>
    ),
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="#fff"
          d="M4 14.5V8h1.5v6.5zm5.25 0V8h1.5v6.5zM2 17v-1.5h16V17zm12.5-2.5V8H16v6.5zM2 7V5l8-4 8 4v2zm2.354-1.5h11.292L10 2.688z"
        ></path>
      </svg>
    ),
  },
];

const Layout = (props: LayoutProps) => {
  const { children } = props;
  return <AntdLayout {...props}>{children}</AntdLayout>;
};
