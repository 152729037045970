import { notification } from 'antd';
import axios from 'axios';
import { useQuery } from 'react-query';

import { CB_ADAPTER_BASE_URL, CB_WEB_URL } from '@/config';

const messageTitle = 'Credit Book';
const errorMessage = 'Something went wrong. try again later!';

const getCreditBookUrl = () => {
  return axios.get(`/xstak-credit-book/api/v1/token`, { baseURL: CB_ADAPTER_BASE_URL });
};

export const useGetCreditBook = (
  setIsTandCModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return useQuery(['creditbook-stage'], () => getCreditBookUrl(), {
    enabled: false,
    onSuccess: (response: any) => {
      const { data, message } = response?.data;
      notification.success({ message: messageTitle, description: message });
      if (data?.accessToken) {
        const accountId = localStorage.getItem('realm');
        const urlFinal = `${CB_WEB_URL}/auth/${accountId}/${data?.accessToken}`;
        window.open(urlFinal, '_blank');
      } else {
        notification.error({ message: messageTitle, description: errorMessage });
      }
      setIsTandCModal(false);
    },
    onError: () => {
      notification.error({ message: messageTitle, description: errorMessage });
    },
  });
};
